export enum DataTestIds {
  GO_BACK_TO_OVERVIEW_BUTTON = 'goBackToOverviewButton',
  USER_MANUAL_INSTRUCTIONS_CONTAINER = 'userManualInstructionsContainer',
  USER_MANUAL_INSTRUCTIONS_TEXT_CONTAINER = 'userManualInstructionsTextContainer',
  VIEW_USER_MANUAL_BUTTON = 'viewUserManualButton',
  REQUEST_SERVICE_BUTTON = 'requestServiceButton',
  REPAIR_DETAILS_REPAIR_STATUS_CARD = 'repairDetailsRepairStatusCard',
  REPAIR_DETAILS_CASE_NUMBER = 'repairDetailsCaseNumber',
  ESTIMATED_REPAIR_COMPLETION_DATE = 'estimatedRepairCompletionDate',
  CHECKPOINT = 'checkpoint', // the full data-testid value is composed with additional parts (each stage of the repair process)
  ASSET_SERVICE_CONTRACT_CARD_CONTRACT_TYPE = 'assetServiceContractCardContractType',
  ASSET_SERVICE_CONTRACT_CARD_REPAIR_INCLUDED = 'assetServiceContractCardRepairIncluded',
  ASSET_SERVICE_CONTRACT_CARD_LOANER_ENTITLED = 'assetServiceContractCardLoanerEntitled',
  ASSET_SERVICE_CONTRACT_CARD_END_OF_SERVICE_CONTRACT = 'assetServiceContractCardEndOfServiceContract',
  SERVICE_PORTAL_BUTTON = 'servicePortalButton',
  DEVICE_SUPPORT_TROUBLESHOOTING_STATUS_ALERT = 'deviceSupportTroubleshootingStatusAlert',
  REPAIR_DETAILS_STATUS_ALERT = 'repairDetailsStatusAlert',
  SCOPE_ERROR_MINUTES_AGO = 'scopeErrorMinutesAgo',
  POSSIBLE_CAUSES_TEXT = 'possibleCausesText',
  REPAIR_DETAILS_STATUS_ALERT_TEXT = 'repairDetailsStatusAlertText',
  STATUS_CARD_HEADER = 'statusCardHeader',
  ASSET_STATUS_CARD_STATUS = 'assetStatusCardStatus',
  ASSET_STATUS_CARD_SERIAL_NUMBER = 'assetStatusCardSerialNumber',
  ASSET_STATUS_CARD_LAST_CONNECTED_TO = 'assetStatusCardLastConnectedTo',
  ASSET_STATUS_CARD_INNER_DIAMETER = 'assetStatusCardInnerDiameter',
  ASSET_STATUS_CARD_OUTER_DIAMETER = 'assetStatusCardOuterDiameter',
  ASSET_NAME = 'asset-name', // the full data-testid value is composed with additional parts (each asset id)
  ASSET_LOANER = 'asset-loaner', // the full data-testid value is composed with additional parts (each asset id)
  ASSET_SERIAL_NUMBER = 'asset-serialNumber', // the full data-testid value is composed with additional parts (each asset id)
  ASSET_STATUS = 'asset-status', // the full data-testid value is composed with additional parts (each asset id)
  ASSET_SERVICE_CONTRACT_COVERAGE = 'asset-serviceContractCoverage', // the full data-testid value is composed with additional parts (each asset id)
  ASSET_DETAIL = 'asset-detail', // the full data-testid value is composed with additional parts (each asset id)
  SORT_DEVICES_COLUMN = 'sortDevicesColumn',
  SORT_STATUSES_COLUMN = 'sortStatusesColumn',
  SORT_CONTRACT_COVERAGE_COLUMN = 'sortContractCoverageColumn',
  FILTER_SECTION = 'filterSection', // the full data-testid value is composed with additional parts (filter section title)
  CLOSE_FILTERS_BUTTON = 'closeFiltersButton',
  VIEW_RESULTS_BUTTON = 'viewResultsButton',
  RESET_FILTERS_BUTTON = 'resetFiltersButton',
  DEVICES_RESULTS_COUNT = 'devicesResultsCount',
  SEARCH_DEVICES_INPUT = 'searchDevicesInput',
  FILTERS_BUTTON = 'filtersButton',
  STATUS_CARD = 'statusCard', // the full data-testid value is composed with additional parts (each status card icon name)
  DATA_UPDATED_TIME = 'dataUpdatedTime',
  STATUS_CARD_LOANER_BADGE = 'statusCardLoanerBadge',
}
