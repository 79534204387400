import { DataTestIds } from '@/@types';
import { useDashboardDataContext } from '@/hooks';
import { Icon, Skeleton } from '@/ui';
import { useTranslation } from 'react-i18next';
import { StatusCardStatus } from '../interfaces';

interface StatusCardProps {
  status: StatusCardStatus;
  total: number;
}

export const StatusCard: React.FC<StatusCardProps> = ({ status, total }) => {
  const { t } = useTranslation();
  const { icon, translationKey, count } = status;
  const { isFetching } = useDashboardDataContext();

  return (
    <div
      className="min-h-100 grow basis-5/12 rounded-4 border-1 border-white bg-white p-24 md:flex md:max-w-332 md:basis-0 md:flex-col lg:flex-row"
      data-testid={`${DataTestIds.STATUS_CARD}-${icon}`}
    >
      <Icon name={icon} className="mr-16 h-48 w-48" />
      <div className="h-48">
        <h2 className="mt-8 text-14_21 font-normal text-base lg:mt-0">
          {t(translationKey, { defaultValue: translationKey })}
        </h2>
        {isFetching ? (
          <Skeleton className="w-112" />
        ) : (
          <span className="h-24 text-gray-8">
            <span className="text-24_34 font-semibold text-base">{count}</span>{' '}
            <span className="align-text-top text-14_22">
              {t('dashboard.statusCards.of')} {total}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};
