import { REACT_APP_DOMAIN } from '@/configs/environment';

const domain = REACT_APP_DOMAIN;

const apiBase = `${domain}/api`;

export const ApiRoute = {
  Assets: `${apiBase}/Assets`,
  SignalR: `${domain}/hubs/deviceStatus`,
  ErrorInfo: `${domain}/GetErrorInfo`,
  DeviceModels: `${apiBase}/DeviceModels`,
  TenantSettings: `${apiBase}/TenantSettings`,
  TenantLanguage: `${apiBase}/Language`,
} as const;

export enum AppRoute {
  AssetDetails = '/asset-detail',
  ChangeEmail = '/change-email',
}
