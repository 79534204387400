import { de, enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export const useUserLocale = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  switch (locale) {
    case 'es-ES':
      return es;
    case 'de-DE':
      return de;
    case 'en-US':
    default:
      return enUS;
  }
};
