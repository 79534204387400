import { ContractCoverage, Device, DeviceStatus } from '@/@types';
import { Skeleton, TableCell } from '@/ui';

const skeletonRow: Device[] = Array.from({ length: 10 }, (_, index) => ({
  id: index.toString(),
  deviceCode: '',
  serialNumber: '',
  deviceStatus: DeviceStatus.IN_USE,
  contractCoverage: ContractCoverage.COVERED,
  isLoaner: false,
  deviceType: '',
  name: '',
  estimatedDelivery: new Date(),
}));

export const DashboardTableSkeleton = () => {
  return (
    <tbody>
      {skeletonRow.map((device) => (
        <tr
          key={device.id}
          className="border-y-1 border-gray-4 first:border-t-[0px] last:border-b-[0px]"
        >
          <TableCell variant="boldCell" className="min-w-213" padding="default">
            <Skeleton />
            <Skeleton className="mt-8 w-2/4 max-w-130 lg:w-130" />
          </TableCell>
          <TableCell className="hidden w-255 sm:table-cell" padding="default">
            <Skeleton className="max-w-64 md:w-3/4 lg:w-2/4" />
          </TableCell>
          <TableCell padding="mobile" className="w-255">
            <Skeleton className="max-w-112 sm:w-2/4 md:w-full lg:w-4/5" />
          </TableCell>
          <TableCell className="hidden w-255 lg:table-cell" padding="default">
            <Skeleton className="max-w-96 lg:w-1/4" />
          </TableCell>
          <TableCell className="w-93" variant="lastCell" padding="mobile">
            <Skeleton className="ml-auto w-2/4 sm:w-70 md:w-93" />
          </TableCell>
        </tr>
      ))}
    </tbody>
  );
};
