import { AssetCareError, DeviceStatus, ErrorOrigin } from '@/@types';
import { withScrollToTop } from '@/hocs';
import { useParams } from 'react-router-dom';
import {
  AssetDetailsSkeleton,
  Header,
  RepairErrorCard,
  ServiceContractCard,
  StatusCard,
} from './components';
import { useAssetDetailsData } from './hooks';

const AssetDetails = () => {
  const { id } = useParams();
  const {
    data: assetDetail,
    isPending,
    isSuccess,
    dataUpdatedAt,
    errorUpdatedAt,
    error,
    fetchStatus,
  } = useAssetDetailsData(id!);

  if (error || (window.navigator.onLine && fetchStatus === 'paused')) {
    throw new AssetCareError(
      'No internet connection.',
      400,
      ErrorOrigin.ASSETDETAILS
    );
  }

  if (!assetDetail && !isPending) return <p>Not found</p>;

  return (
    <div className="flex flex-col gap-y-16">
      <Header lastUpdated={isSuccess ? dataUpdatedAt : errorUpdatedAt} />
      {isPending ? (
        <AssetDetailsSkeleton />
      ) : (
        <>
          <StatusCard
            scopeType={assetDetail.deviceType}
            scopeModel={assetDetail.name}
            isLoaner={assetDetail.isLoaner}
            serialNumber={assetDetail.serialNumber}
            connectedTo={assetDetail.connectedTo}
            innerDiameter={assetDetail.innerDiameter}
            outerDiameter={assetDetail.outerDiameter}
            status={assetDetail.deviceStatus}
          />
          {!assetDetail.isLoaner && (
            <ServiceContractCard
              contractType={assetDetail.contractType}
              repairIncluded={assetDetail.isRepairIncluded}
              loanerEntitled={assetDetail.isLoanerEntitled}
              endOfServiceContract={assetDetail.endOfServiceContract}
            />
          )}
          {(assetDetail.deviceStatus === DeviceStatus.ERROR_DETECTED ||
            assetDetail.deviceStatus === DeviceStatus.IN_REPAIR) && (
            <RepairErrorCard
              status={assetDetail.deviceStatus}
              errorMessage={assetDetail.errorInfo?.errorName}
              minutesAgo={assetDetail.minutesAgo}
              errorSolutions={assetDetail.errorInfo?.errorSolutions ?? []}
              isLoanerRequested={assetDetail.isLonerRequested}
              caseNumber={assetDetail.caseNumber}
              repairCompletionDate={assetDetail.repairEstimation}
              repairStep={assetDetail.repairStatus}
              userManual={assetDetail.userManual}
            />
          )}
        </>
      )}
    </div>
  );
};

export const AssetDetailsWithScrollToTop = withScrollToTop(AssetDetails);
