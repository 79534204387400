import { AppRoute, ContractCoverage, DataTestIds, Device } from '@/@types';
import { LoanerBadge, StatusBadge, TableCell } from '@/ui';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

interface DashboardTableBodyProps {
  sortedDevices: Device[];
}

export const DashboardTableBody: React.FC<DashboardTableBodyProps> = ({
  sortedDevices,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewDetails = (id: string) => {
    navigate(`${AppRoute.AssetDetails}/${id}`, {
      state: { searchParams: location.search },
    });
  };

  return (
    <tbody className="relative z-0">
      {sortedDevices.map((device: Device) => (
        <tr
          id={device.id}
          key={device.id}
          className="h-88 border-1 border-l-[0px] border-r-[0px] border-gray-3 first:border-t-[0px] last:border-b-[0px]"
        >
          <TableCell
            variant="boldCell"
            padding="mobile"
            className="min-w-213 text-12_16.8 sm:text-16_24"
            data-testid={`${DataTestIds.ASSET_NAME}/${device.id}`}
          >
            <div className="flex flex-col sm:flex-row sm:gap-8">
              <p className="flex items-center gap-6 ">{device.name}</p>
              <LoanerBadge
                className={cn(
                  device.isLoaner
                    ? 'flex sm:px-8 sm:py-2 sm:text-12_18'
                    : 'hidden'
                )}
                dataTestId={`${DataTestIds.ASSET_LOANER}/${device.id}`}
              />
            </div>
            <p className="mt-4 font-normal text-gray-8 ">{device.deviceType}</p>
          </TableCell>
          <TableCell
            className="hidden w-255 text-12_16.8 sm:table-cell sm:text-16_24"
            padding="mobile"
            data-testid={`${DataTestIds.ASSET_SERIAL_NUMBER}/${device.id}`}
          >
            {device.serialNumber}
          </TableCell>
          <TableCell
            padding="mobile"
            className="w-255"
            data-testid={`${DataTestIds.ASSET_STATUS}/${device.id}`}
          >
            <StatusBadge status={device.deviceStatus} />
          </TableCell>
          <TableCell
            className="hidden w-255 lg:table-cell"
            padding="mobile"
            data-testid={`${DataTestIds.ASSET_SERVICE_CONTRACT_COVERAGE}/${device.id}`}
          >
            {device.contractCoverage === ContractCoverage.COVERED &&
              t('dashboard.table.tableBody.covered')}
            {device.contractCoverage === ContractCoverage.NOT_COVERED &&
              t('dashboard.table.tableBody.not_covered')}
          </TableCell>
          <TableCell
            variant="lastCell"
            padding="mobile"
            className="w-100 text-end"
          >
            <button
              onClick={() => handleViewDetails(device.id)}
              className="text-wrap text-12_16.8 sm:text-nowrap md:text-nowrap md:text-16_24"
              data-testid={`${DataTestIds.ASSET_DETAIL}/${device.id}`}
            >
              {t('dashboard.table.tableBody.viewDetails')}
            </button>
          </TableCell>
        </tr>
      ))}
    </tbody>
  );
};
