import { cn } from '@/utils';
import { VariantProps, cva } from 'class-variance-authority';

export type IconName =
  | 'notInUse'
  | 'inUse'
  | 'inRepair'
  | 'error'
  | 'search'
  | 'filter'
  | 'asc'
  | 'ascDisabled'
  | 'desc'
  | 'chevronLeft'
  | 'chevronRight'
  | 'arrow-left'
  | 'ellipse'
  | 'close'
  | 'checkmark'
  | 'openInNew'
  | 'infoCircle'
  | 'warning'
  | 'check'
  | 'tryAgain'
  | 'olympusLogoBlack'
  | 'olysenseLogoGray';

const iconVariants = cva('', {
  variants: {
    size: {
      xl: 'h-24 w-24',
    },
  },
  defaultVariants: {
    size: 'xl',
  },
});

interface IconProps
  extends React.SVGProps<SVGSVGElement>,
    VariantProps<typeof iconVariants> {
  name: IconName;
}

export const Icon: React.FC<IconProps> = ({
  name,
  size,
  className,
  ...props
}) => {
  return (
    <svg className={cn(iconVariants({ size, className }))} {...props}>
      <use href={`/sprite.svg#${name}`} />
    </svg>
  );
};
