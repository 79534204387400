import { clsx, type ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-10_16.8',
        'text-12_16.8',
        'text-12_18',
        'text-12_20',
        'text-14_16',
        'text-14_19',
        'text-14_20',
        'text-14_21',
        'text-14_22',
        'text-14_24',
        'text-16_20',
        'text-16_22',
        'text-16_24',
        'text-18_28',
        'text-20_28',
        'text-24_32',
        'text-30_38',
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
