import { DataTestIds } from '@/@types';
import { Icon, LastUpdated } from '@/ui';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface HeaderProps {
  lastUpdated: number;
}

export const Header: React.FC<HeaderProps> = ({ lastUpdated }) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    const searchParams = location.state?.searchParams ?? '';

    navigate(`/${searchParams}`, { state: { id: params.id } });
  };

  return (
    <div className="flex w-full justify-between">
      <button
        onClick={handleGoBack}
        className="flex items-center justify-center"
        data-testid={DataTestIds.GO_BACK_TO_OVERVIEW_BUTTON}
      >
        <Icon name="arrow-left" />
        <p className="ml-6.67 hidden text-14_21 md:block">
          {t('headers.goBackToOverview')}
        </p>
      </button>
      <LastUpdated lastQueryUpdate={lastUpdated} />
    </div>
  );
};
