import { DataTestIds, DeviceStatus } from '@/@types';
import { Icon } from '@/ui';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';
import { ErrorSolution } from '../interfaces';

interface StatusAlertProps {
  status: DeviceStatus.ERROR_DETECTED | DeviceStatus.IN_REPAIR;
  errorMessage?: string;
  errorSolutions?: ErrorSolution[];
  minutesAgo?: number;
  isLoanerRequested?: boolean;
}

export const StatusAlert: React.FC<StatusAlertProps> = ({
  status,
  errorMessage,
  minutesAgo,
  errorSolutions,
  isLoanerRequested,
}) => {
  const { t } = useTranslation();

  const isErrorDetected = status === DeviceStatus.ERROR_DETECTED;

  return (
    <div
      className={cn(
        'flex gap-x-16 rounded-4 border-1 p-16 shadow-statusAlert',
        isErrorDetected
          ? 'border-statusErrorBorder bg-statusErrorBg'
          : 'border-statusInRepairBorder bg-statusInRepairBg'
      )}
      data-testid={`${isErrorDetected ? DataTestIds.DEVICE_SUPPORT_TROUBLESHOOTING_STATUS_ALERT : DataTestIds.REPAIR_DETAILS_STATUS_ALERT}`}
    >
      <Icon
        name={isErrorDetected ? 'warning' : 'infoCircle'}
        className={cn(
          'h-24 w-24',
          isErrorDetected ? 'text-statusErrorBorder' : 'text-iconInfo'
        )}
      />
      <div className="flex flex-col">
        {isErrorDetected && (
          <div className="mb-2 flex flex-wrap items-center gap-4">
            <p className="text-16_24 font-semibold text-text-component">
              {errorMessage}
            </p>
            {minutesAgo && (
              <p
                className="text-12_16.8 text-text-soft"
                data-testid={DataTestIds.SCOPE_ERROR_MINUTES_AGO}
              >
                {minutesAgo} {t('assetDetail.minAgo')}
              </p>
            )}
          </div>
        )}

        {isErrorDetected && (
          <>
            <p className="text-16_24 font-semibold text-text-component">
              {t('assetDetail.possibleCauses')}
            </p>
            <p
              className="whitespace-pre-line text-16_24 text-text-base"
              data-testid={DataTestIds.POSSIBLE_CAUSES_TEXT}
            >
              {errorSolutions
                ?.map((solution) => solution.possibleCauses)
                .join(' \n')}
            </p>
          </>
        )}

        {!isErrorDetected && (
          <>
            <p className="text-16_24 font-semibold text-gray-9">
              {t('assetDetail.deviceIsInRepair')}
            </p>
            <p
              className="text-16_24 text-text-base"
              data-testid={DataTestIds.REPAIR_DETAILS_STATUS_ALERT_TEXT}
            >
              {isLoanerRequested
                ? t('assetDetail.loanDeviceRequested')
                : t('assetDetail.loanDeviceNotRequested')}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
