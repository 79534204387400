import { DataTestIds } from '@/@types';
import { useUserLocale } from '@/hooks';
import { Card } from '@/ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface ServiceContractCardProps {
  contractType: string;
  repairIncluded: boolean;
  loanerEntitled: boolean;
  endOfServiceContract: Date;
}

export const ServiceContractCard: React.FC<ServiceContractCardProps> = ({
  contractType,
  repairIncluded,
  loanerEntitled,
  endOfServiceContract,
}) => {
  const { t } = useTranslation();
  const locale = useUserLocale();

  return (
    <Card>
      <div className="flex flex-col gap-y-16 xl:gap-y-24">
        <h1 className="text-14_21 font-semibold text-text-base xl:text-16_24">
          {t('assetDetail.serviceContractDetails')}
        </h1>
        <div className="flex flex-col gap-24 md:flex-row md:flex-wrap md:gap-32 xl:gap-56">
          <div
            className="flex flex-col justify-center gap-y-8"
            data-testid={DataTestIds.ASSET_SERVICE_CONTRACT_CARD_CONTRACT_TYPE}
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.contractType')}
            </p>
            <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
              {contractType}
            </p>
          </div>
          <div
            className="flex flex-col justify-center gap-y-8"
            data-testid={
              DataTestIds.ASSET_SERVICE_CONTRACT_CARD_REPAIR_INCLUDED
            }
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.repairIncluded')}
            </p>
            <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
              {repairIncluded ? t('assetDetail.yes') : t('assetDetail.no')}
            </p>
          </div>
          <div
            className="flex flex-col justify-center gap-y-8"
            data-testid={
              DataTestIds.ASSET_SERVICE_CONTRACT_CARD_LOANER_ENTITLED
            }
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.loanerEntitled')}
            </p>
            <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
              {loanerEntitled ? t('assetDetail.yes') : t('assetDetail.no')}
            </p>
          </div>
          <div
            className="flex flex-col justify-center gap-y-8"
            data-testid={
              DataTestIds.ASSET_SERVICE_CONTRACT_CARD_END_OF_SERVICE_CONTRACT
            }
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.endOfServiceContract')}
            </p>
            <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
              {format(endOfServiceContract, 'dd.MM.yyyy', {
                locale,
              })}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};
