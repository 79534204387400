import { Icon } from '@/ui';
import { useTranslation } from 'react-i18next';

interface NoDataProps {
  onViewAllClick: () => void;
}

export const NoData: React.FC<NoDataProps> = ({ onViewAllClick }) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto mt-20 flex min-h-149 min-w-174 flex-col items-center justify-center p-24 lg:mt-80">
      <Icon name="infoCircle" className="max-h-18 max-w-18 mb-24 text-base" />
      <p className="mb-8 text-16_24 font-normal text-base">
        {t('dashboard.noData.noResults')}
      </p>
      <button
        className="text-14_21 font-semibold text-blue-6"
        onClick={onViewAllClick}
      >
        {t('dashboard.noData.viewAllDevices')}
      </button>
    </div>
  );
};
