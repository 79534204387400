import { DataTestIds } from '@/@types';
import { filterSidebarId } from '@/constants';
import { useDashboardDataContext } from '@/hooks';
import { Icon, Skeleton, Tooltip } from '@/ui';
import { cn } from '@/utils';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FilterSidebar } from './FilterSidebar';

interface SearchFilterSectionProps {
  maxCount: number;
  count: number;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  filterParamsCount: number;
}

const scrollToFilterSection = (isFilterOpen: boolean) => {
  const body = document.querySelector('body');
  const largeScreen = window.matchMedia('(min-width: 1024px)').matches;
  const mediumScreen = window.matchMedia('(min-width: 768px)').matches;
  const smallScreen = window.matchMedia('(min-width: 640px)').matches;
  const extraSmallScreen = window.matchMedia('(max-width: 639px)').matches;

  if (!isFilterOpen && largeScreen) {
    body?.scrollTo({
      top: 198,
      left: 0,
      behavior: 'smooth',
    });
  } else if (!isFilterOpen && mediumScreen) {
    body?.scrollTo({
      top: 238,
      left: 0,
      behavior: 'smooth',
    });
  } else if (!isFilterOpen && smallScreen) {
    body?.scrollTo({
      top: 404,
      left: 0,
      behavior: 'smooth',
    });
  } else if (!isFilterOpen && extraSmallScreen) {
    body?.scrollTo({
      top: 406,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export const SearchFilterSection: React.FC<SearchFilterSectionProps> = ({
  maxCount,
  count,
  searchTerm,
  setSearchTerm,
  filterParamsCount,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const { t } = useTranslation();
  const { isFetching } = useDashboardDataContext();

  const shouldRenderDevices = searchTerm === '' && filterParamsCount === 0;

  const resolvedTitle = shouldRenderDevices
    ? t('dashboard.filters.devices')
    : t('dashboard.filters.results');

  const resolvedValue = shouldRenderDevices
    ? maxCount
    : `${count} ${t('dashboard.statusCards.of')} ${maxCount}`;

  const filterParamsCountText = `(${filterParamsCount})`;
  const filterButtonText = `${t('dashboard.filters.filters')} ${filterParamsCount > 0 ? filterParamsCountText : ''}`;

  return (
    <div className="relative flex w-full flex-col items-center justify-between bg-gray-11 py-16 sm:flex-row">
      <div className="mb-16 flex min-w-134 items-center gap-8 sm:mb-0">
        <h2 className="text-16_24 font-semibold xl:text-20_28">
          {resolvedTitle}
        </h2>
        {isFetching ? (
          <Skeleton className="w-51 px-16 py-4" />
        ) : (
          <span
            className="block min-w-51 flex-row justify-center rounded-48 bg-white px-16 py-4 align-middle text-16_20 font-semibold text-gray-10 md:m-0 md:inline-flex"
            data-testid={DataTestIds.DEVICES_RESULTS_COUNT}
          >
            {resolvedValue}
          </span>
        )}
      </div>

      <div className="flex items-center justify-center">
        <div
          className="relative bg-white"
          onMouseOver={() => setIsTooltipShown(true)}
          onMouseLeave={() => setIsTooltipShown(false)}
        >
          {isTooltipShown && (
            <Tooltip text={t('dashboard.filters.searchPlaceholder')} />
          )}
          <Icon
            name="search"
            className="absolute left-12 top-12 hidden h-16 w-16 text-base md:flex"
          />
          <input
            className="h-40 overflow-ellipsis rounded-4 border-1 border-gray-3 pl-16 pr-16 outline-0 placeholder:text-14_21 placeholder:font-normal placeholder:text-gray-10 hover:border-blue-6 focus:border-blue-8 md:w-320 md:pl-36"
            type="text"
            placeholder={t('dashboard.filters.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.trimStart())}
            data-testid={DataTestIds.SEARCH_DEVICES_INPUT}
          />
          {searchTerm !== '' ? (
            <Icon
              name="close"
              className="absolute right-12 top-12 flex h-16 w-16 cursor-pointer text-base"
              onClick={() => setSearchTerm('')}
            />
          ) : null}
        </div>
        <button
          className={cn(
            'ml-16 flex h-40 items-center justify-center rounded-4 bg-blue-6 text-14_19 text-white',
            filterParamsCount > 0
              ? 'min-w-90 md:min-w-120'
              : 'min-w-74 md:min-w-100'
          )}
          onClick={() => {
            scrollToFilterSection(isFilterOpen);
            setIsFilterOpen(!isFilterOpen);
          }}
          data-testid={DataTestIds.FILTERS_BUTTON}
        >
          <Icon className="mr-8 hidden h-16 w-16 md:flex" name="filter" />
          {filterButtonText}
        </button>
      </div>

      {isFilterOpen &&
        createPortal(
          <FilterSidebar
            filterParamsCount={filterParamsCount}
            onClose={() => setIsFilterOpen(false)}
          />,
          document.getElementById(filterSidebarId)!
        )}
    </div>
  );
};
