import { Card, Icon, Skeleton } from '@/ui';
import { t } from 'i18next';
import { ServicePortalButton } from './ServicePortalButton';

const assetDetailsFirstCard = [
  'assetDetail.status',
  'assetDetail.serialNumber',
  'assetDetail.connectedTo',
  'assetDetail.innerDiameter',
  'assetDetail.outerDiameter',
];

const assetDetailsSecondCard = [
  'assetDetail.contractType',
  'assetDetail.repairIncluded',
  'assetDetail.loanerEntitled',
  'assetDetail.endOfServiceContract',
];

export const AssetDetailsSkeleton = () => {
  return (
    <>
      <Card>
        <div className="flex flex-col gap-y-16 md:gap-y-24">
          <div className="flex justify-between">
            <div className="flex grow items-center gap-8 text-16_24 font-semibold text-text-heading xl:text-20_28">
              <h1>Olympus</h1>
              <Icon name="ellipse" className="h-4 w-4" />
              <Skeleton className="max-w-130" />
              <Icon name="ellipse" className="h-4 w-4" />
              <Skeleton className="max-w-130" />
            </div>
            <ServicePortalButton href="a" className="hidden md:block" />
          </div>
          <div className="flex flex-col gap-24 md:flex-row md:flex-wrap md:gap-32 xl:gap-56">
            {assetDetailsFirstCard.map((detail) => (
              <div className="flex flex-col gap-y-8" key={detail}>
                <p className="text-12_16.8 tracking-0.24 text-text-softer">
                  {t(detail, { defaultValue: detail })}
                </p>
                <Skeleton className="w-90 max-w-130" />
              </div>
            ))}
            <ServicePortalButton href="a" className="md:hidden" />
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex flex-col gap-y-16 xl:gap-y-24">
          <h1 className="text-14_21 font-semibold text-text-base xl:text-16_24">
            {t('assetDetail.serviceContractDetails')}
          </h1>
          <div className="flex flex-col gap-24 md:flex-row md:flex-wrap md:gap-32 xl:gap-56">
            {assetDetailsSecondCard.map((detail) => (
              <div className="flex flex-col gap-y-8" key={detail}>
                <p className="text-12_16.8 tracking-0.24 text-text-softer">
                  {t(detail, { defaultValue: detail })}
                </p>
                <Skeleton />
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};
