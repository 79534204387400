import { filterSidebarId } from '@/constants';
import { useDashboardDataContext } from '@/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import { DashboardHeader, DashboardTable, StatusCardList } from './components';

export const Dashboard = () => {
  const { data, dashDataError, signalRError } = useDashboardDataContext();

  const { showBoundary } = useErrorBoundary();

  if (dashDataError) showBoundary(dashDataError);

  return (
    <main>
      <DashboardHeader hospitalName="" signalRError={signalRError} />
      <StatusCardList devices={data} />
      {/* div here listed for filter-sidebar portal */}
      <div id={filterSidebarId} className="sticky top-64 z-1000" />
      <DashboardTable />
    </main>
  );
};
