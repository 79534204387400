import { useDashboardDataContext } from '@/hooks';
import { LastUpdated } from '@/ui';
import { useTranslation } from 'react-i18next';

interface DashboardHeaderProps {
  hospitalName: string;
  signalRError?: Error | null;
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  hospitalName,
  signalRError,
}) => {
  const { fetchStatus, lastUpdated } = useDashboardDataContext();
  const { t } = useTranslation();

  return (
    <div className="mt-8 flex items-center justify-between pb-8 lg:pb-16">
      <h1 className="text-24_34 font-semibold">{hospitalName}</h1>
      <div className="flex items-center gap-8">
        {(fetchStatus === 'paused' && window.navigator.onLine) ||
          (signalRError && (
            <div className="text-12_16 rounded-2 bg-connectionLostError px-8 pb-2 pt-1 font-noto_sans text-gray-11">
              {t('headers.dataLoadError')}
            </div>
          ))}
        <LastUpdated lastQueryUpdate={lastUpdated} />
      </div>
    </div>
  );
};
