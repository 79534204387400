import { DashboardDataContext } from '@/providers/DashboardDataProvider';
import { useContext } from 'react';

export const useDashboardDataContext = () => {
  const context = useContext(DashboardDataContext);

  if (context === undefined) {
    throw new Error(
      'useDashboardDataContext must be used within a DashboardDataProvider'
    );
  }

  return context;
};
