import { cn } from '@/utils';

interface CardProps {
  className?: string;
  children: React.ReactNode;
  dataTestId?: string;
}

export const Card: React.FC<CardProps> = ({
  className,
  children,
  dataTestId,
}) => {
  return (
    <div
      className={cn(
        'w-full overflow-hidden rounded-8 bg-white p-16 md:p-24',
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
