import { DataTestIds } from '@/@types';
import { useUpdateUrlParam } from '@/hooks';
import { Divider, Icon } from '@/ui';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SearchParams } from '../enums';

interface FilterItem {
  searchParam: SearchParams;
  title: string;
  items: {
    key: string;
    value: string;
  }[];
}

interface FilterCategoryProps {
  filterItem: FilterItem;
  shouldRenderDivider: boolean;
}

export const FilterCategory: React.FC<FilterCategoryProps> = ({
  filterItem,
  shouldRenderDivider,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { updateUrlParam, removeUrlParam } = useUpdateUrlParam();

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: string
  ) => {
    const { name, checked } = event.target;

    const translatedName = t(name, { defaultValue: name });

    if (checked) {
      return updateUrlParam(category, translatedName);
    }

    removeUrlParam(category, translatedName);
  };

  const filterItemTitleArr = filterItem.title.split('.');

  return (
    <div
      key={filterItem.title}
      className="mt-14 first:mt-0"
      data-testid={`${DataTestIds.FILTER_SECTION}-${filterItemTitleArr[filterItemTitleArr.length - 1]}`}
    >
      <h2 className="mb-8 font-segoe_ui text-14_22 font-semibold text-gray-7">
        {t(filterItem.title, { defaultValue: filterItem.title })}
      </h2>
      <div className="mb-14 flex flex-col last:mb-0">
        {filterItem.items.map((item) => (
          <div
            key={item.key}
            className="mb-10 flex items-center gap-8 last:mb-0"
          >
            <div className="relative inline-flex items-center justify-center">
              <input
                className="peer relative h-16 w-16 cursor-pointer appearance-none rounded-2 border-1 border-gray-5 checked:border-blue-6 checked:bg-blue-6 hover:border-blue-6"
                type="checkbox"
                id={item.key}
                name={item.value}
                checked={
                  searchParams
                    .get(filterItem.searchParam)
                    ?.split(',')
                    .includes(item.value) ?? false
                }
                onChange={(event) =>
                  handleCheckboxChange(event, filterItem.searchParam)
                }
              />
              <Icon
                name="checkmark"
                className="pointer-events-none absolute left-2 top-2 hidden h-12 w-12 text-white peer-checked:block"
              />
            </div>
            <label
              className="cursor-pointer text-14_22 font-normal text-gray-9"
              htmlFor={item.key}
            >
              {t(item.key, { defaultValue: item.key })}
            </label>
          </div>
        ))}
      </div>
      {shouldRenderDivider && <Divider />}
    </div>
  );
};
