import { ApiRoute, AssetCareError, ErrorOrigin } from '@/@types';
import { getCurrentLanguage } from '@/utils';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useQuery } from '@tanstack/react-query';
import {
  AssetDetailsResponse,
  ErrorInfoResponse,
  UserManualResponse,
} from '../interfaces';

const errorOrigin = ErrorOrigin.ASSETDETAILS;

const fetchAssetDetailsQuery = async (assetId: string) => {
  if (!window.navigator.onLine) {
    throw new AssetCareError('No internet connection.', 400, errorOrigin);
  }

  const response = await fetchWithAuth(`${ApiRoute.Assets}/${assetId}`);

  if (!response.ok) {
    throw new AssetCareError(
      `Data fetching failed with status: ${response.status} and status text: ${response.statusText}`,
      response.status,
      errorOrigin
    );
  }

  const apiJson: AssetDetailsResponse = await response.json();

  return apiJson;
};

const fetchErrorInfoQuery = async (errorCode: string, language: string) => {
  const response = await fetchWithAuth(
    `${ApiRoute.ErrorInfo}?errorCode=${errorCode}&language=${language}`
  );

  if (!response.ok) {
    throw new Error(
      `Data fetching failed with status: ${response.status} and status text: ${response.statusText}`
    );
  }

  const apiJson: ErrorInfoResponse = await response.json();

  return apiJson;
};

//TODO: Replace CV-1500 with deviceModel when it becomes available on BE
const fetchUserManualQuery = async (
  language: string,
  deviceModel: string = 'CV-1500'
) => {
  const response = await fetchWithAuth(
    `${ApiRoute.DeviceModels}/${deviceModel}/manuals/latest?language=${language}`
  );

  if (!response.ok) {
    throw new Error(
      `Data fetching failed with status: ${response.status} and status text: ${response.statusText}`
    );
  }

  const apiJson: UserManualResponse = await response.json();

  return apiJson;
};

const fetchAssetDetailsAndAppendError = async (deviceId: string) => {
  const assetDetailsResponse = await fetchAssetDetailsQuery(deviceId);

  if (!assetDetailsResponse.errorCode) {
    return assetDetailsResponse;
  }
  const language = getCurrentLanguage();

  const [errorInfoResponse, userManualResponse] = await Promise.all([
    fetchErrorInfoQuery(assetDetailsResponse.errorCode, language),
    fetchUserManualQuery(language),
  ]);

  return {
    ...assetDetailsResponse,
    errorInfo: errorInfoResponse,
    userManual: userManualResponse,
  };
};

export const useAssetDetailsData = (deviceId: string) => {
  return useQuery({
    queryKey: ['assetDetails', deviceId],
    queryFn: () => fetchAssetDetailsAndAppendError(deviceId),
  });
};
