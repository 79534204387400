import { SignalRContext } from '@/providers/SignalRProvider';
import { useContext } from 'react';

export function useSignalRContext() {
  const context = useContext(SignalRContext);

  if (context === undefined) {
    throw new Error('useSignalRContext must be used within a SignalRProvider');
  }

  return context;
}
