import { Device } from '@/@types';
import { useMemo, useState } from 'react';
import { SortParams } from '../enums';

const deviceStatusSorter = (
  a: Device,
  b: Device,
  sortDirection: 'asc' | 'desc'
) => {
  if (a[SortParams.DeviceStatus] < b[SortParams.DeviceStatus]) {
    return sortDirection === 'asc' ? 1 : -1;
  }
  if (a[SortParams.DeviceStatus] > b[SortParams.DeviceStatus]) {
    return sortDirection === 'asc' ? -1 : 1;
  }

  if (a[SortParams.Name] < b[SortParams.Name]) {
    return -1;
  }
  if (a[SortParams.Name] > b[SortParams.Name]) {
    return 1;
  }

  return 0;
};

const deviceCodeSorter = (
  a: Device,
  b: Device,
  sortDirection: 'asc' | 'desc'
) => {
  if (a[SortParams.Name] < b[SortParams.Name]) {
    return sortDirection === 'asc' ? -1 : 1;
  }
  if (a[SortParams.Name] > b[SortParams.Name]) {
    return sortDirection === 'asc' ? 1 : -1;
  }

  return 0;
};

const contractCoverageSorter = (
  a: Device,
  b: Device,
  sortDirection: 'asc' | 'desc'
) => {
  if (a[SortParams.ContractCoverage] < b[SortParams.ContractCoverage]) {
    return sortDirection === 'asc' ? -1 : 1;
  }
  if (a[SortParams.ContractCoverage] > b[SortParams.ContractCoverage]) {
    return sortDirection === 'asc' ? 1 : -1;
  }

  if (a[SortParams.Name] < b[SortParams.Name]) {
    return -1;
  }
  if (a[SortParams.Name] > b[SortParams.Name]) {
    return 1;
  }

  return 0;
};

export const useSort = (devices: Device[]) => {
  const [sortColumn, setSortColumn] = useState<keyof Device>(SortParams.Name);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const sortedDevices = useMemo(() => {
    return devices.sort((a: Device, b: Device) => {
      if (sortColumn === SortParams.DeviceStatus) {
        return deviceStatusSorter(a, b, sortDirection);
      } else if (sortColumn === SortParams.Name) {
        return deviceCodeSorter(a, b, sortDirection);
      } else if (sortColumn === SortParams.ContractCoverage) {
        return contractCoverageSorter(a, b, sortDirection);
      }
      return 0;
    });
  }, [devices, sortColumn, sortDirection]);

  const handleSort = (column: keyof Device) => {
    if (column === sortColumn) {
      return setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }

    setSortColumn(column);
    setSortDirection('asc');
  };

  return {
    sortColumn,
    sortDirection,
    handleSort,
    sortedDevices,
  };
};
