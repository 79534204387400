import { I18N_LANGUAGE } from '@/constants';

export const getCurrentLanguage = () => {
  const language = localStorage.getItem(I18N_LANGUAGE) || 'en';

  if (language.includes('-')) {
    return language.split('-')[0];
  }

  return language;
};
