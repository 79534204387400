import { DataTestIds } from '@/@types';
import { useUserLocale } from '@/hooks';
import { Card, Icon } from '@/ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ServiceStep } from '../enums';
import { RepairStatusStepper } from './RepairStatusStepper';

interface RepairStatusCardProps {
  caseNumber: number;
  repairCompletionDate: Date;
  repairStep: ServiceStep;
}

export const RepairStatusCard: React.FC<RepairStatusCardProps> = ({
  caseNumber,
  repairCompletionDate,
  repairStep,
}) => {
  const { t } = useTranslation();
  const locale = useUserLocale();

  return (
    <Card
      className="flex flex-col gap-y-24 border-1 border-statusNotInUseBg md:gap-y-32 md:p-16"
      dataTestId={DataTestIds.REPAIR_DETAILS_REPAIR_STATUS_CARD}
    >
      <div className="flex flex-col gap-x-8 gap-y-4 text-14_21 font-semibold text-text-base md:flex-row md:items-center xl:text-16_24">
        <p>{t('assetDetail.repairStatus')}</p>
        <Icon
          name="ellipse"
          className="hidden h-4 w-4 text-statusSoftNeutral md:block"
        />
        <p data-testid={DataTestIds.REPAIR_DETAILS_CASE_NUMBER}>
          <span className="font-normal text-text-title xl:text-14_21">
            {t('assetDetail.caseNumber')}
          </span>
          &nbsp;
          {caseNumber}
        </p>
      </div>
      <RepairStatusStepper currentStep={repairStep} />
      <div
        className="flex flex-col gap-y-8"
        data-testid={DataTestIds.ESTIMATED_REPAIR_COMPLETION_DATE}
      >
        <p className="text-12_16.8 tracking-0.24 text-text-soft">
          {t('assetDetail.estimatedRepairCompletionDate')}
        </p>
        <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
          {format(repairCompletionDate, 'EEEE, MMMM do yyyy', {
            locale,
          })}
        </p>
      </div>
    </Card>
  );
};
