import { Device } from '@/@types';
import { StatusCardStatus } from '../interfaces';
import { getStatusInfo } from '../utils';
import { StatusCard } from './StatusCard';
interface StatusCardListProps {
  devices: Device[] | undefined;
}

export const StatusCardList: React.FC<StatusCardListProps> = ({ devices }) => {
  const statusInfo = getStatusInfo(devices ?? []);

  const statuses: StatusCardStatus[] = [
    {
      icon: 'notInUse',
      translationKey: 'dashboard.statusCards.notInUse',
      count: statusInfo.notInUse,
    },
    {
      icon: 'inUse',
      translationKey: 'dashboard.statusCards.inUse',
      count: statusInfo.inUse,
    },
    {
      icon: 'inRepair',
      translationKey: 'dashboard.statusCards.inRepair',
      count: statusInfo.inRepair,
    },
    {
      icon: 'error',
      translationKey: 'dashboard.statusCards.errorDetected',
      count: statusInfo.errorDetected,
    },
  ];

  return (
    <div className="flex w-full flex-wrap gap-16 py-16">
      {statuses.map((status) => (
        <StatusCard
          key={status.translationKey}
          status={status}
          total={statusInfo.total}
        />
      ))}
    </div>
  );
};
