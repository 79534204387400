import { cn } from '@/utils';

interface TooltipProps {
  text: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ text }) => {
  return (
    <div
      className={cn(
        'absolute left-1/2 z-100 flex min-h-32 min-w-304 -translate-x-1/2 animate-fadeIn items-center justify-center rounded-8 border-1 border-gray-4 bg-gray-3 px-8 py-6 tracking-wide shadow-tooltip',
        text.length > 46 ? '-top-54' : '-top-42'
      )}
    >
      <span className="text-12_16.8 text-base">{text}</span>
      <div className="absolute left-1/2 top-full z-100 h-0 w-0 border-l-5 border-r-5 border-t-5 border-transparent border-t-gray-3" />
      <div className="absolute left-1/2 top-full z-0 -ml-1 h-0 w-0 border-l-6 border-r-6 border-t-6 border-transparent border-t-gray-4" />
    </div>
  );
};
