import { cn } from '@/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const tableCellVariants = cva('', {
  variants: {
    variant: {
      default: 'text-16_24 text-gray-9',
      lastCell:
        'w-auto md:w-[1%] whitespace-nowrap text-16_24 font-semibold text-brand-primary_6',
      boldCell: 'text-16_24 font-semibold text-headingColor',
      mobile: 'text-12_16 md:text-16_24 text-gray-9',
    },
    padding: {
      default: 'py-16 px-10 md:px-24 md:py-16',
      sm: 'px-24 py-8 ',
      mobile: 'px-8 py-4 md:px-24 md:py-16',
    },
  },
  defaultVariants: {
    variant: 'default',
    padding: 'default',
  },
});

interface TableCellProps
  extends React.TdHTMLAttributes<HTMLTableCellElement>,
    VariantProps<typeof tableCellVariants> {}

export const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ variant, padding, className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(tableCellVariants({ variant, padding, className }))}
      {...props}
    />
  )
);
TableCell.displayName = 'TableCell';
