import { DataTestIds } from '@/@types';
import { differenceInMinutes } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LastUpdatedProps {
  lastQueryUpdate: number;
}

export const LastUpdated: React.FC<LastUpdatedProps> = ({
  lastQueryUpdate,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const lastUpdatedMinutes = useMemo(() => {
    if (lastQueryUpdate === 0) {
      return lastQueryUpdate;
    }

    return differenceInMinutes(date, lastQueryUpdate);
  }, [date, lastQueryUpdate]);

  const resolvedText = `${t('headers.lastUpdated')} ${lastUpdatedMinutes === 0 ? t('headers.justNow') : `${lastUpdatedMinutes} ${t('headers.minAgo')}`}`;

  return (
    <p
      className="text-12_16 text-gray-6"
      data-testid={DataTestIds.DATA_UPDATED_TIME}
    >
      {resolvedText}
    </p>
  );
};
