import { cn } from '@/utils';

export const Skeleton: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        'h-24 w-[70%] animate-pulse rounded-4 bg-statusNotInUseBg',
        className
      )}
      {...props}
    />
  );
};
