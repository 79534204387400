import { DataTestIds, DeviceStatus } from '@/@types';
import { Card, Icon, LoanerBadge, StatusBadge } from '@/ui';
import { useTranslation } from 'react-i18next';
import { ServicePortalButton } from './ServicePortalButton';

interface StatusCardProps {
  scopeType: string;
  scopeModel: string;
  isLoaner: boolean;
  serialNumber: string;
  connectedTo: string;
  innerDiameter?: string;
  outerDiameter?: string;
  status: DeviceStatus;
}

export const StatusCard: React.FC<StatusCardProps> = ({
  scopeType,
  scopeModel,
  isLoaner,
  serialNumber,
  connectedTo,
  innerDiameter,
  outerDiameter,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="flex flex-col gap-y-16 md:gap-y-24">
        <div className="flex justify-between">
          <div
            className="flex flex-wrap items-center gap-8 text-16_24 font-semibold text-text-heading xl:text-20_28"
            data-testid={DataTestIds.STATUS_CARD_HEADER}
          >
            <h1>Olympus</h1>
            <Icon name="ellipse" className="h-4 w-4" />
            <h1>{scopeType}</h1>
            <Icon name="ellipse" className="h-4 w-4" />
            <h1>{scopeModel}</h1>
            {isLoaner && (
              <LoanerBadge
                className="md:ml-8"
                dataTestId={DataTestIds.STATUS_CARD_LOANER_BADGE}
              />
            )}
          </div>
          <ServicePortalButton
            href="https://olympus-europa--oaxe27.sandbox.my.site.com/services/auth/sso/keycloak?startURL=/s/asset/02i0E000009bwxQQAQ/32-lmdxh320st-sony-monitor"
            className="hidden md:block"
          />
        </div>
        <div className="flex flex-col items-baseline gap-24 md:flex-row md:flex-wrap md:gap-32 xl:gap-56">
          <div
            className="flex flex-col justify-center gap-y-6.67"
            data-testid={DataTestIds.ASSET_STATUS_CARD_STATUS}
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.status')}
            </p>
            <StatusBadge status={status} />
          </div>
          <div
            className="flex flex-col justify-center gap-y-8"
            data-testid={DataTestIds.ASSET_STATUS_CARD_SERIAL_NUMBER}
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.serialNumber')}
            </p>
            <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
              {serialNumber}
            </p>
          </div>
          <div
            className="flex flex-col justify-center gap-y-8"
            data-testid={DataTestIds.ASSET_STATUS_CARD_LAST_CONNECTED_TO}
          >
            <p className="text-12_16.8 tracking-0.24 text-text-softer">
              {t('assetDetail.connectedTo')}
            </p>
            <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
              {connectedTo}
            </p>
          </div>
          {innerDiameter && (
            <div
              className="flex flex-col justify-center gap-y-8"
              data-testid={DataTestIds.ASSET_STATUS_CARD_INNER_DIAMETER}
            >
              <p className="text-12_16.8 tracking-0.24 text-text-softer">
                {t('assetDetail.innerDiameter')}
              </p>
              <p className="text-16_24 font-semibold text-text-base xl:text-20_28">
                {innerDiameter}
              </p>
            </div>
          )}
          {outerDiameter && (
            <div
              className="flex flex-col justify-center gap-y-8"
              data-testid={DataTestIds.ASSET_STATUS_CARD_OUTER_DIAMETER}
            >
              <p className="text-12_16.8 tracking-0.24 text-text-softer">
                {t('assetDetail.outerDiameter')}
              </p>
              <p className="text-16_24.8 font-semibold text-text-base xl:text-20_28">
                {outerDiameter}
              </p>
            </div>
          )}
          <ServicePortalButton
            href="https://olympus-europa--oaxe27.sandbox.lightning.force.com/"
            className="md:hidden"
          />
        </div>
      </div>
    </Card>
  );
};
