import { cn } from '@/utils';
import React from 'react';

export const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'px-8 py-8 text-start text-12_16.8 font-normal text-gray-8 sm:px-8 sm:text-14_21 md:px-24 md:py-12 lg:py-12',
      className
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';
