import { DataTestIds } from '@/@types';
import { Icon } from '@/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceStep } from '../enums';

type RepairStepTranslationType =
  | 'orderCreated'
  | 'inspection'
  | 'inRepair'
  | 'shipped'
  | 'invoice';
interface RepairStatusStepperProps {
  currentStep: ServiceStep;
}

const translationMap: Record<ServiceStep, RepairStepTranslationType> = {
  [ServiceStep.ORDER_CREATED]: 'orderCreated',
  [ServiceStep.INSPECTION]: 'inspection',
  [ServiceStep.IN_REPAIR]: 'inRepair',
  [ServiceStep.SHIPPED]: 'shipped',
  [ServiceStep.INVOICE]: 'invoice',
};

export const RepairStatusStepper: React.FC<RepairStatusStepperProps> = ({
  currentStep,
}) => {
  const { t } = useTranslation();

  const currentStepIndex = Object.values(ServiceStep).indexOf(currentStep);
  const nextStep = Object.values(ServiceStep)[currentStepIndex + 1];
  const circumference = 2 * Math.PI * 37;

  const isLastStep = currentStepIndex === Object.values(ServiceStep).length - 1;

  return (
    <>
      {/* Web design*/}
      <div className="hidden md:flex xl:gap-x-8 xl:px-16">
        {Object.values(ServiceStep).map((step, index) => (
          <React.Fragment key={step}>
            <div
              className="flex flex-col items-center gap-y-8 text-center lg:min-w-fit"
              data-testid={`${step}${DataTestIds.CHECKPOINT}`}
            >
              {index === currentStepIndex && (
                <div className="flex h-24 w-24 items-center justify-center rounded-full bg-iconInfo p-4">
                  <p className="text-12_16.8 text-white">{index + 1}</p>
                </div>
              )}
              {index < currentStepIndex && (
                <div className="flex h-24 w-24 items-center justify-center rounded-full bg-iconSuccess p-4">
                  <Icon name="check" className="text-white" />
                </div>
              )}
              {index > currentStepIndex && (
                <div className="flex h-24 w-24 items-center justify-center rounded-full bg-statusNotInUseBg p-4">
                  <p className="text-12_16.8 text-statusSoftNeutral">
                    {index + 1}
                  </p>
                </div>
              )}
              <p className="text-12_16.8 tracking-0.24 text-text-soft">
                {t(`assetDetail.repairSteps.${translationMap[step]}`)}
              </p>
            </div>
            {index < Object.values(ServiceStep).length - 1 && (
              <div className="mt-13.27 h-2 w-full bg-statusNotInUseBg"></div>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* Mobile design*/}
      <div className="flex items-center gap-x-24 md:hidden">
        <div className="relative">
          <svg
            className="size-full -rotate-90"
            width="78"
            height="78"
            viewBox="0 0 78 78"
          >
            <circle
              cx="39"
              cy="39"
              r="37"
              fill="none"
              strokeWidth="4"
              stroke="#D8DBE2"
            ></circle>
            <circle
              cx="39"
              cy="39"
              r="37"
              fill="none"
              stroke="#0068F0"
              strokeWidth="4"
              strokeDasharray={circumference}
              strokeDashoffset={
                circumference -
                ((currentStepIndex + 1) / Object.values(ServiceStep).length) *
                  circumference
              }
            ></circle>
          </svg>
          <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-12_16.8 text-text-soft">
            {currentStepIndex + 1} of {Object.values(ServiceStep).length}
          </p>
        </div>
        <div className="flex flex-col gap-y-8">
          <p className="text-16_24 font-semibold text-text-base">
            {t(`assetDetail.repairSteps.${translationMap[currentStep]}`)}
          </p>
          {!isLastStep && (
            <p className="text-12_16.8 text-text-soft">
              {t('assetDetail.nextStep')}:&nbsp;
              {t(`assetDetail.repairSteps.${translationMap[nextStep]}`)}
            </p>
          )}
        </div>
      </div>
    </>
  );
};
