import { getConfig, getToken } from '@novax/os';

export const fetchWithAuth = (
  url: string | URL | globalThis.Request,
  options: RequestInit = {}
) => {
  const token = getToken() ?? '';
  const config = getConfig();
  const tenantId = config?.AUTH_REALM ?? '';

  return fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      'Nova-Tenant-Id': tenantId,
      ...options.headers,
    },
  });
};
