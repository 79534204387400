import { DataTestIds } from '@/@types';
import { useUpdateUrlParam } from '@/hooks';
import { Icon } from '@/ui';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';
import FilterCategoryList from './FilterCategoryList';

interface FilterSidebarProps {
  onClose: () => void;
  filterParamsCount: number;
}

export const FilterSidebar: React.FC<FilterSidebarProps> = ({
  onClose,
  filterParamsCount,
}) => {
  const { t } = useTranslation();
  const { resetFilters } = useUpdateUrlParam();

  const isButtonDisabled = filterParamsCount === 0;

  return (
    <div className="absolute right-1 top-114 z-100 flex h-[calc(100vh-182px)] max-h-569 w-255 flex-col gap-16 rounded-4 border-1 border-gray-3 bg-white px-24 py-16 shadow-filterShadow sm:top-70 sm:h-[calc(100vh-138px)] sm:w-373">
      <div className="flex items-center justify-between">
        <h1 className="text-12_16.8 font-semibold uppercase tracking-widest text-gray-9">
          {t('dashboard.filters.filters')}
        </h1>
        <Icon
          name="close"
          className="h-24 w-24 cursor-pointer text-blue-6"
          onClick={onClose}
          data-testid={DataTestIds.CLOSE_FILTERS_BUTTON}
        />
      </div>
      <div className="grow overflow-y-auto custom-scrollbar">
        <FilterCategoryList />
      </div>
      <div className="flex flex-col gap-4">
        <button
          className="h-40 max-w-325 rounded-4 bg-blue-6 font-noto_sans text-14_20 font-medium text-gray-11"
          onClick={onClose}
          data-testid={DataTestIds.VIEW_RESULTS_BUTTON}
        >
          {t('dashboard.filters.viewResults')}
        </button>
        <button
          className={cn(
            'mt-4 text-center font-noto_sans text-14_21 font-medium',
            isButtonDisabled
              ? 'pointer-events-none text-gray-6'
              : 'cursor-pointer text-blue-6'
          )}
          onClick={resetFilters}
          data-testid={DataTestIds.RESET_FILTERS_BUTTON}
        >
          {t('dashboard.filters.resetFilters')}
        </button>
      </div>
    </div>
  );
};
