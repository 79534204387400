import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';
import { AssetCareError } from './@types';
import {
  AppGuard,
  AuthProvider,
  DashboardDataProvider,
  SignalRProvider,
} from './providers';
import { queryClient } from './query';
import { router } from './router';
import { reactPlugin } from './services/ApplicationInsightsService';
import { ErrorPage } from './ui';

const App = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        appInsights={reactPlugin}
        onError={() => (
          <ErrorPage
            error={new AssetCareError('', 500)}
            resetErrorBoundary={() => {}}
          />
        )}
      >
        <AuthProvider>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <AppGuard>
              <SignalRProvider>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <DashboardDataProvider>
                    <RouterProvider router={router} />
                  </DashboardDataProvider>
                </QueryClientProvider>
              </SignalRProvider>
            </AppGuard>
          </ErrorBoundary>
        </AuthProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default App;
