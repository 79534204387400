/* eslint-disable @typescript-eslint/no-explicit-any*/
function getEnv(envName: string) {
  return (window as any).env
    ? (window as any).env[envName]
    : import.meta.env[envName];
}

export const REACT_APP_ENVIRONMENT = getEnv('REACT_APP_ENVIRONMENT');
export const REACT_APP_AUTH_URL = getEnv('REACT_APP_AUTH_URL');
export const REACT_APP_AUTH_CLIENT_ID = getEnv('REACT_APP_AUTH_CLIENT_ID');
export const REACT_APP_AUTH_REALM = getEnv('REACT_APP_AUTH_REALM');
export const REACT_APP_OS_HOST = getEnv('REACT_APP_OS_HOST');
export const REACT_APP_DOMAIN = getEnv('REACT_APP_DOMAIN');
export const REACT_APP_INSIGHTS_CONNECTION = getEnv(
  'REACT_APP_INSIGHTS_CONNECTION'
);
export const REACT_APP_MODULE_URL_REPORT = getEnv(
  'REACT_APP_MODULE_URL_REPORT'
);
