import { ApiRoute, AssetCareError, Device, ErrorOrigin } from '@/@types';
import { useSignalRContext } from '@/hooks';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useQuery } from '@tanstack/react-query';
import { createContext, useMemo } from 'react';

type DashboardDataProviderType = {
  data: Device[] | undefined;
  isFetching?: boolean;
  fetchStatus?: 'fetching' | 'paused' | 'idle';
  availableDeviceTypes: string[];
  lastUpdated: number;
  dashDataError: Error | null;
  signalRError: Error | null;
};

export const DashboardDataContext = createContext<DashboardDataProviderType>({
  data: undefined,
  isFetching: undefined,
  fetchStatus: 'fetching',
  availableDeviceTypes: [],
  lastUpdated: 0,
  dashDataError: null,
  signalRError: null,
});

const fetchQuery = async () => {
  if (!window.navigator.onLine) {
    throw new AssetCareError(
      'No internet connection.',
      400,
      ErrorOrigin.DASHBOARDDATA
    );
  }

  const response = await fetchWithAuth(ApiRoute.Assets);

  if (!response.ok) {
    throw new AssetCareError(
      `Data fetching failed with status: ${response.status} and status text: ${response.statusText}`,
      response.status
    );
  }
  const apiJson: Device[] = await response.json();
  return apiJson;
};

export const DashboardDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { message, error: signalRError } = useSignalRContext();
  const {
    data,
    isFetching,
    error: dashDataError,
    isSuccess,
    dataUpdatedAt,
    errorUpdatedAt,
    fetchStatus,
  } = useQuery({
    queryKey: ['dashboard'],
    queryFn: fetchQuery,
  });

  const availableDeviceTypes = useMemo(() => {
    const availableDeviceTypes = new Set<string>();

    data?.forEach((device) => {
      availableDeviceTypes.add(device.deviceType);
    });

    return Array.from(availableDeviceTypes);
  }, [data]);

  const lastUpdated = useMemo(() => {
    if (message) return new Date().getTime();

    return isSuccess ? dataUpdatedAt : errorUpdatedAt;
  }, [message, isSuccess, dataUpdatedAt, errorUpdatedAt]);

  return (
    <DashboardDataContext.Provider
      value={{
        data: data,
        isFetching,
        fetchStatus,
        availableDeviceTypes,
        lastUpdated: lastUpdated,
        dashDataError,
        signalRError,
      }}
    >
      {children}
    </DashboardDataContext.Provider>
  );
};
