import { ApiRoute } from '@/@types';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useQuery } from '@tanstack/react-query';
import { TenantLanguageResponse } from '../interfaces';

const fetchTenantLanguage = async (tenantId: string) => {
  const response = await fetchWithAuth(
    `${ApiRoute.TenantLanguage}?tenantId=${tenantId}`
  );

  if (!response.ok) {
    return null;
  }

  const apiJson: TenantLanguageResponse = await response.json();

  return apiJson;
};

export const useTenantLanguage = (tenantId: string) => {
  return useQuery({
    queryKey: ['tenantLanguage', tenantId],
    queryFn: () => fetchTenantLanguage(tenantId),
  });
};
