import { AppRoute } from '@/@types';
import { AssetDetails, ChangeEmail, Dashboard } from '@/features';
import { ErrorPage, NotFound, Root } from '@/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: (
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Dashboard />
          </ErrorBoundary>
        ),
      },
      {
        path: `${AppRoute.AssetDetails}/:id`,
        element: (
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <AssetDetails />
          </ErrorBoundary>
        ),
      },
      {
        path: AppRoute.ChangeEmail,
        element: (
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <ChangeEmail />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
