import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

interface LoanerBadgeProps {
  className?: string;
  dataTestId?: string;
}

export const LoanerBadge: React.FC<LoanerBadgeProps> = ({
  className,
  dataTestId,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'flex w-max items-center justify-center rounded-4 border-1 border-gray-4 px-6 py-2 text-12_16.8 font-normal tracking-wide text-headingColor shadow-[0px_0.5px_1px_0px_rgba(16,24,40,0.05)] sm:px-10 sm:py-3 sm:text-16_24',
        className
      )}
      data-testid={dataTestId}
    >
      <span>{t('shared.loaner')}</span>
    </div>
  );
};
