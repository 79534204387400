import { DataTestIds, Device } from '@/@types';
import { Icon, TableHead } from '@/ui';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';
import { SortParams } from '../enums';

interface DashboardTableHeadProps {
  onSort: (column: keyof Device) => void;
  sortColumn: keyof Device;
  sortDirection: 'asc' | 'desc';
}

const headCellClassName = 'cursor-pointer font-semibold';
const headCellContentClassName = 'flex items-center gap-8 text-14_21 text-base';

export const DashboardTableHead: React.FC<DashboardTableHeadProps> = ({
  onSort,
  sortColumn,
  sortDirection,
}) => {
  const { t } = useTranslation();

  const renderSortIcon = (column: keyof Device) => {
    if (column !== sortColumn) return <Icon name="ascDisabled" />;

    return sortDirection === 'asc' ? <Icon name="asc" /> : <Icon name="desc" />;
  };

  return (
    <thead
      className="sticky top-178
            z-20 overflow-hidden rounded-t-4 border-b-[0px] border-t-[0px] border-gray-4 bg-white shadow-tableHeader 
            outline-transparent before:absolute before:left-[-1px] before:top-[-1px] before:z-10 before:h-4 before:w-4 before:rounded-tl-4 before:border-l-1 before:border-t-1 before:border-gray-4 before:bg-white before:content-[''] after:absolute after:right-[-1px] after:top-[-1px] after:z-10 after:h-4 after:w-4 after:rounded-tr-4 after:border-r-1 after:border-t-1 after:border-gray-4 after:bg-white after:content-[''] sm:top-134"
    >
      <tr>
        <TableHead
          onClick={() => onSort(SortParams.Name)}
          className={cn(headCellClassName, 'w-213')}
        >
          <div className={headCellContentClassName}>
            {t('dashboard.table.tableHead.device')}
            <span data-testid={DataTestIds.SORT_DEVICES_COLUMN}>
              {renderSortIcon(SortParams.Name)}
            </span>
          </div>
        </TableHead>
        <TableHead
          className={cn(
            headCellClassName,
            'hidden w-255 cursor-default sm:table-cell'
          )}
        >
          <div className={cn(headCellContentClassName, 'text-nowrap')}>
            {t('dashboard.table.tableHead.serialNumber')}
          </div>
        </TableHead>
        <TableHead
          onClick={() => onSort(SortParams.DeviceStatus)}
          className={cn(headCellClassName, 'w-255')}
        >
          <div className={headCellContentClassName}>
            {t('dashboard.table.tableHead.status')}
            <span data-testid={DataTestIds.SORT_STATUSES_COLUMN}>
              {renderSortIcon(SortParams.DeviceStatus)}
            </span>
          </div>
        </TableHead>
        <TableHead
          onClick={() => onSort(SortParams.ContractCoverage)}
          className={cn(headCellClassName, 'hidden w-255 lg:table-cell')}
        >
          <div className={cn(headCellContentClassName, 'text-nowrap')}>
            {t('dashboard.table.tableHead.contractCoverage')}
            <span data-testid={DataTestIds.SORT_CONTRACT_COVERAGE_COLUMN}>
              {renderSortIcon(SortParams.ContractCoverage)}
            </span>
          </div>
        </TableHead>
        <TableHead className="pointer-events-none w-100"></TableHead>
      </tr>
    </thead>
  );
};
