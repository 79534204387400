import React, { useEffect } from 'react';

export const withScrollToTop = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const EnhancedComponent = (props: P) => {
    useEffect(() => {
      document.body.scrollTo(0, 0);
    }, []);

    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};
