import { AuthState, LoadingScreen, NoAccessScreen, useAuth } from '@novax/os';
import { useEffect, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

// We have to set this up as high as possible in the component tree,
// so we can hide the app if user is not logged in
export const AppGuard = ({ children }: Props) => {
  const { user, isAuthReady, authState, login } = useAuth();

  const [shouldShowNoAccessScreen, setShouldShowNoAccessScreen] =
    useState(false);

  useEffect(() => {
    // Only try to login if we don't have a user, and we are not trying to logout...
    const userNeedsLogin = !user && authState !== AuthState.LOGGING_OUT;

    if (isAuthReady && userNeedsLogin) {
      login();
    }
  }, [user, isAuthReady, login, authState]);

  // [Effect] Handle redirect if the user is not authenticated
  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED) {
      setShouldShowNoAccessScreen(false);
    } else {
      setShouldShowNoAccessScreen(true);
    }
  }, [shouldShowNoAccessScreen, authState]);

  return (
    <>
      {shouldShowNoAccessScreen && <NoAccessScreen />}
      {authState === AuthState.LOGGING_IN && <LoadingScreen />}
      {authState === AuthState.AUTHENTICATED && children}
    </>
  );
};
