import { ApiRoute, AssetCareError, ErrorOrigin } from '@/@types';
import { cn } from '@/utils';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteEmailProps {
  emailId?: number;
}

const deleteEmailFromEmailFeatureQuery = async (emailId?: number) => {
  if (!emailId) {
    return;
  }
  if (!window.navigator.onLine) {
    throw new AssetCareError('', 400, ErrorOrigin.CHANGEEMAIL);
  }

  const response = await fetchWithAuth(
    `${ApiRoute.TenantSettings}/${emailId}`,
    {
      method: 'DELETE',
    }
  );
  if (!response.ok) {
    throw new AssetCareError('', response.status, ErrorOrigin.CHANGEEMAIL);
  }

  return;
};

export const DeleteEmailForm: React.FC<DeleteEmailProps> = ({ emailId }) => {
  const { t } = useTranslation();
  const [deleteEmailSuccess, setDeleteEmailSuccess] = useState('');
  const [deleteEmailFailure, setDeleteEmailFailure] = useState('');
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => deleteEmailFromEmailFeatureQuery(emailId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getTenantEmail'] });
      setDeleteEmailSuccess(t('changeEmailPage.successfulDeletion'));
      setTimeout(() => setDeleteEmailSuccess(''), 4000);
    },
    onError: (error) => {
      setDeleteEmailFailure(t('changeEmailPage.somethingWentWrong'));
      setTimeout(() => setDeleteEmailFailure(''), 4000);
      console.log('Error:', error);
    },
  });

  const deleteEmailHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDeleteEmailSuccess('');
    mutation.mutate();
  };

  return (
    <form
      onSubmit={deleteEmailHandler}
      className={cn(
        'mx-auto flex flex-col gap-12 rounded-4 border-1 border-gray-4 p-20 sm:w-[80%] md:w-[50%]',
        (deleteEmailSuccess && !emailId) || emailId
          ? 'opacity-100'
          : 'opacity-50'
      )}
    >
      <p>{t('changeEmailPage.deleteEmailFromEmailFeature')}</p>
      <button
        className={cn(
          'mx-auto max-w-max rounded-4 border-1 border-error-medium px-16 py-8 text-error-medium hover:bg-error-medium hover:text-white',
          !emailId && 'pointer-events-none border-gray-5 text-gray-5'
        )}
        disabled={!emailId}
      >
        {t('changeEmailPage.deleteEmail')}
      </button>
      {deleteEmailSuccess && (
        <p className="animate-fadeIn py-8 text-green-500">
          {deleteEmailSuccess}
        </p>
      )}
      {deleteEmailFailure && (
        <p className="animate-fadeIn py-8 text-red-500">{deleteEmailFailure}</p>
      )}
    </form>
  );
};
