import { DataTestIds } from '@/@types';
import { Icon } from '@/ui';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

interface ServicePortalButtonProps {
  href: string;
  className?: string;
}

export const ServicePortalButton: React.FC<ServicePortalButtonProps> = ({
  href,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <a
      href={href}
      target="_blank"
      className={cn(
        'rounded-4 border-1 border-button-secondaryIdleBorder bg-button-secondaryIdleBg px-16 py-12',
        className
      )}
      role="button"
      rel="noreferrer"
      data-testid={DataTestIds.SERVICE_PORTAL_BUTTON}
    >
      <div className="flex items-center justify-center gap-x-8 font-semibold text-text-buttonSecondaryIdle">
        <p className="text-12_16.8">
          {t('assetDetail.buttons.viewInServicePortal')}
        </p>
        <Icon name="openInNew" className="h-16 w-16" />
      </div>
    </a>
  );
};
