import {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REALM,
  REACT_APP_AUTH_URL,
  REACT_APP_MODULE_URL_REPORT,
  REACT_APP_OS_HOST,
} from '@/configs/environment';
import { Login, NovaAutoLogout, NovaOSApp } from '@novax/os';

interface Props {
  children: React.ReactNode;
}

type AuthConfig = {
  AUTH_URL: string;
  AUTH_CLIENT_ID: string;
  TENANT_ID: string;
  AUTH_REALM?: string;
  OS_HOST?: string;
  MODULE_URL_INSIGHTS: string;
  MODULE_URL_REPORT: string;
  MODULE_URL_USERADMIN: string;
};

const LOGOUT_TIMEOUT = 10; // minutes
// const isDevelopmentMode = REACT_APP_ENVIRONMENT === 'local';
const authUrl = REACT_APP_AUTH_URL;
const authClientId = REACT_APP_AUTH_CLIENT_ID;
const authRealm = REACT_APP_AUTH_REALM;
const osHost = REACT_APP_OS_HOST;
const tenantId = REACT_APP_AUTH_REALM;
const moduleUrlReport = REACT_APP_MODULE_URL_REPORT;

const authConfig: AuthConfig = {
  AUTH_URL: authUrl,
  AUTH_CLIENT_ID: authClientId,
  TENANT_ID: tenantId,
  AUTH_REALM: authRealm,
  OS_HOST: osHost,
  MODULE_URL_INSIGHTS: 'INSIGHTS', // temporary value - waiting for the real values in OS module package
  MODULE_URL_REPORT: moduleUrlReport,
  MODULE_URL_USERADMIN: '',
};

export function AuthProvider({ children }: Props) {
  return (
    <NovaOSApp config={authConfig}>
      <Login>
        {children}
        <NovaAutoLogout timeoutMins={LOGOUT_TIMEOUT} />
      </Login>
    </NovaOSApp>
  );
}
