import { ApiRoute, AssetCareError, ErrorOrigin } from '@/@types';
import { Icon } from '@/ui';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useQuery } from '@tanstack/react-query';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AddSetEmailForm } from './components/AddSetEmailForm';
import { DeleteEmailForm } from './components/DeleteEmailForm';

export const fetchTenantEmailQuery = async () => {
  if (!window.navigator.onLine) {
    throw new AssetCareError('', 400, ErrorOrigin.CHANGEEMAIL);
  }

  const response = await fetchWithAuth(ApiRoute.TenantSettings);
  if (response.status === 404) {
    return '';
  }
  if (!response.ok) {
    throw new AssetCareError('', response.status, ErrorOrigin.CHANGEEMAIL);
  }

  const apiJson = await response.json();
  return apiJson;
};

export const ChangeEmail = () => {
  const { t } = useTranslation();

  const { showBoundary } = useErrorBoundary();

  const {
    data,
    error: fetchError,
    isPending,
  } = useQuery({
    queryKey: ['getTenantEmail'],
    queryFn: () => fetchTenantEmailQuery(),
  });

  if (fetchError) showBoundary(fetchError);

  if (isPending) {
    return <p>Loading...</p>;
  }

  return (
    <section className="flex flex-col gap-24 text-center">
      <h1 className="text-20_28">{t('changeEmailPage.changeEmail')}</h1>
      <p>
        {t('changeEmailPage.currentEmail')}: <br />
        <b>{data?.emailAddress || t('changeEmailPage.emailNotSet')}</b>
      </p>

      <AddSetEmailForm emailId={data?.id} />

      <DeleteEmailForm emailId={data?.id} />

      <Link
        to="/"
        className="mx-auto mt-80 flex max-w-max items-center gap-8 rounded-4 border-1 border-gray-4 p-8 hover:bg-gray-3"
      >
        <Icon name="arrow-left" className="h-20 w-20" />
        {t('changeEmailPage.goBack')}
      </Link>
    </section>
  );
};
