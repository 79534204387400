import { useFilterData } from '../hooks';
import { FilterCategory } from './FilterCategory';

const FilterCategoryList = () => {
  const { filterData } = useFilterData();

  return (
    <div>
      {filterData.map((filterItem, index) => (
        <FilterCategory
          key={index}
          filterItem={filterItem}
          shouldRenderDivider={index !== filterData.length - 1}
        />
      ))}
    </div>
  );
};

export default FilterCategoryList;
