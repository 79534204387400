/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useUpdateUrlParam = () => {
  const [searchParams] = useSearchParams();
  const newParams = new URLSearchParams(searchParams);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hash = typeof window !== 'undefined' ? window.location.hash : '';

  const updateUrlParam = (paramToUpdate: string, value: any) => {
    if (value) {
      const existingParam = newParams.get(paramToUpdate);
      if (existingParam) {
        const val = newParams.get(paramToUpdate)?.split(',');
        val?.push(value);
        newParams.set(paramToUpdate, val?.join(',') ?? '');
      } else {
        newParams.set(paramToUpdate, value);
      }
    } else {
      newParams.delete(paramToUpdate);
    }

    navigate(`${pathname}?${newParams.toString()}${hash}`);
  };

  const removeUrlParam = (paramToRemove: string, value: any) => {
    const existingParam = newParams.get(paramToRemove)?.split(',');

    if (existingParam?.includes(value)) {
      existingParam?.splice(existingParam?.indexOf(value), 1);
      if (existingParam?.length === 0) {
        newParams.delete(paramToRemove);
      } else {
        newParams.set(paramToRemove, existingParam?.join(',') ?? '');
      }
    } else {
      newParams.delete(paramToRemove);
    }

    navigate(`${pathname}?${newParams.toString()}${hash}`);
  };

  const resetFilters = () => {
    navigate(`${pathname}${hash}`);
  };

  return { updateUrlParam, resetFilters, removeUrlParam };
};
