import { Icon } from '../Icon/Icon';

export const Footer = () => {
  return (
    <footer className="mt-20 flex h-74 flex-col justify-center border-t-1 border-gray-4">
      <div className="my-auto flex flex-wrap items-center">
        <Icon name="olympusLogoBlack" className="h-16 w-128" />
        <p className="ml-16 pt-1 font-segoe_ui text-12_16.8 tracking-wider text-gray-8">
          © 2024 Olympus Corporation
        </p>
      </div>
    </footer>
  );
};
