import { ApiRoute, AssetCareError, ErrorOrigin } from '@/@types';
import { getCurrentLanguage } from '@/utils';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AddSetEmailProps {
  emailId?: number;
}

interface AddSetEmailMutation {
  newEmail: string;
  locale: string;
}

const addOrChangeEmailQuery = async (
  newEmail: string,
  locale: string,
  emailId?: number
) => {
  if (!window.navigator.onLine) {
    throw new AssetCareError('', 400, ErrorOrigin.CHANGEEMAIL);
  }

  const bodyObj = emailId
    ? {
        id: emailId,
        email: newEmail,
        locale: locale,
        requestedBy: 'user',
      }
    : { email: newEmail, locale: locale, requestedBy: 'user' };

  const response = await fetchWithAuth(ApiRoute.TenantSettings, {
    method: emailId ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyObj),
  });
  if (!response.ok) {
    throw new AssetCareError('', response.status, ErrorOrigin.CHANGEEMAIL);
  }

  const apiJson = await response.json();
  return apiJson;
};

export const AddSetEmailForm: React.FC<AddSetEmailProps> = ({ emailId }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailSetSuccess, setEmailSetSuccess] = useState('');
  const [emailSetFailure, setEmailSetFailure] = useState('');
  const queryClient = useQueryClient();
  const locale = getCurrentLanguage();

  const mutation = useMutation({
    mutationFn: ({ newEmail, locale }: AddSetEmailMutation) =>
      addOrChangeEmailQuery(newEmail, locale, emailId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getTenantEmail'] });
      setEmail('');
      setEmailSetSuccess(t('changeEmailPage.successfulChange'));
      setTimeout(() => setEmailSetSuccess(''), 3000);
    },
    onError: (error) => {
      setEmailSetFailure(t('changeEmailPage.somethingWentWrong'));
      setTimeout(() => setEmailSetFailure(''), 3000);
      console.error('Error:', error);
    },
  });

  const submitNewEmailHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setEmailSetSuccess('');
    if (!email) {
      setError(t('changeEmailPage.emailRequired'));
      return;
    }
    const emailValidationRegex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    if (!emailValidationRegex.test(email)) {
      setError(t('changeEmailPage.emailInvalid'));
      return;
    }

    mutation.mutate({ newEmail: email, locale: locale });
    setError('');
    setEmail('');
  };

  return (
    <form
      onSubmit={submitNewEmailHandler}
      className="mx-auto flex flex-col gap-12 rounded-4 border-1 border-gray-4 p-20 sm:w-[80%] md:w-[50%]"
    >
      <label htmlFor="email">
        {emailId
          ? t('changeEmailPage.changeEmail')
          : t('changeEmailPage.addEmail')}
        :
      </label>
      <input
        type="email"
        id="email"
        name="email"
        className="mx-auto rounded-4 border-1 border-gray-4 p-4 sm:w-[80%] md:w-[60%]"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      {error && <p className="text-red-500">{error}</p>}

      <button className="mx-auto max-w-max rounded-4 border-1 border-brand-primary_6 px-16 py-8 text-brand-primary_6 hover:bg-brand-primary_6 hover:text-white">
        {emailId
          ? t('changeEmailPage.changeEmail')
          : t('changeEmailPage.addEmail')}
      </button>

      {emailSetSuccess && (
        <p className="animate-fadeIn py-8 text-green-500">{emailSetSuccess}</p>
      )}
      {emailSetFailure && (
        <p className="animate-fadeIn py-8 text-red-500">{emailSetFailure}</p>
      )}
    </form>
  );
};
